import React from 'react';
import {Helmet} from "react-helmet"

import Ico from './images/ico.png'
import Preview from './images/preview.jpg'

export default function Head() {
  return (
    <Helmet>
      <meta content="text/html; charset=UTF-8" name="Content-Type" />

      <title>KikoMints | NFT Derivatives & Vibes</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="icon" href={Ico} />
      <meta name="theme-color" content="#000000" />

      <meta name="description" content="building during the bear market!" />
      <meta name="keywords" content="NFT, NFTs, Crypto, Ethereum, ETH, Mint, Free"/>

      <link rel="apple-touch-icon" href={'https://www.kikomints.com' + Ico} />

      <meta property="og:title" content="KikoMints | NFT Derivatives & Vibes" />
      <meta property="og:description" content="building during the bear market!" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.kikomints.com" />
      <meta property="og:image" content={'https://www.kikomints.com' + Preview} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@KikoMints" />
      <meta name="twitter:creator" content="@KikoMints" />
      <meta name="twitter:title" content="KikoMints | NFT Derivatives & Vibes" />
      <meta name="twitter:description" content='building during the bear market!' />
      <meta name="twitter:image" content={'https://www.kikomints.com' + Preview} />
    </Helmet>
  );
}
