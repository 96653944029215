import React from "react";
import "./style.css";
import { Link } from "gatsby"

export default function MainHeader(props) {
    return (

      <div id="nav">
        <div id="topLogo"></div>
        <div className="options">
          <Link className="text" to="/">HOME</Link>
          <Link className="text" to="/mystics">MYSTICS</Link>
          <Link className="text" to="/bakery">BAKERY</Link>
          <a className="icon discord" href="https://discord.gg/kikomints" target="_blank" rel="noopener noreferrer"></a>
          <a className="icon twitter" href="https://www.twitter.com/kikomints" target="_blank" rel="noopener noreferrer"></a>
          <a className="icon looksrare" href="https://looksrare.org/collections/0x745735600DCf9562060BEcDAE9A1a0AFfFcd9Cf6?queryID=dd393ed4fb5755190fe5ffcd1b5f78e2" target="_blank" rel="noopener noreferrer"></a>
          <a className="icon opensea" href="https://opensea.io/collection/kikobakes" target="_blank" rel="noopener noreferrer"></a>
        </div>
      </div>
    );
}
