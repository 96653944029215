import React, { Component } from 'react'
import "./style.css";

import ReactPlayer from 'react-player'
import Track1 from './tracks/beanBeatz.mp3'
import Track2 from './tracks/lunarArrival.mp3'
import Track3 from './tracks/irlBreak.mp3'
import Track4 from './tracks/nostalgicApe.mp3'

let tracks = [
  {url: Track1, title: 'bean beatz', artist: 'josta'},
  {url: Track2, title: 'lunar arrival', artist: 'ghost texture'},
  {url: Track3, title: 'irl break', artist: 'juan mandagie'},
  {url: Track4, title: 'nostalgic ape', artist: 'josta'},
]

let currentTrack = Math.floor(Math.random() * tracks.length)

class App extends Component {

  state = {
    url: tracks[currentTrack].url,
    title: tracks[currentTrack].title,
    artist: tracks[currentTrack].artist,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    })
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

  handleEnded = () => {
    currentTrack = currentTrack < tracks.length - 1? currentTrack + 1 : 0
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
    this.setState({ playing: true })
  }

  handleNext = () => {
    currentTrack = currentTrack < tracks.length - 1? currentTrack + 1 : 0
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
  }

  handleBack = () => {
    currentTrack = currentTrack <= 0? tracks.length - 1 : currentTrack - 1
    this.setState({ url: tracks[currentTrack].url})
    this.setState({ artist: tracks[currentTrack].artist})
    this.setState({ title: tracks[currentTrack].title})
  }

  ref = player => {
    this.player = player
  }

  render() {
    const { url, title, artist, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
    const SEPARATOR = ' · '
    return (
      <div id="audioPlayer" class={playing ? '' : 'unactive'}>
        <ReactPlayer
          ref={this.ref}
          className='react-player'
          width='100%'
          height='100%'
          url={url}
          title={title}
          artist={artist}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={() => console.log('onReady')}
          onStart={() => console.log('onStart')}
          onPlay={this.handlePlay}
          onEnablePIP={this.handleEnablePIP}
          onDisablePIP={this.handleDisablePIP}
          onPause={this.handlePause}
          onBuffer={() => console.log('onBuffer')}
          onPlaybackRateChange={this.handleOnPlaybackRateChange}
          onSeek={e => console.log('onSeek', e)}
          onEnded={this.handleEnded}
          onError={e => console.log('onError', e)}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
        <div id="audioPlayerTitle">
          {title}
        </div>
        <div id="audioPlayerArtist">
          {artist}
        </div>
        <div id="audioControls">
          <button className="control back" onClick={this.handleBack}></button>
          <button className={playing? 'control pause' : 'control play'} onClick={this.handlePlayPause}></button>
          <button className="control next" onClick={this.handleNext}></button>
        </div>
      </div>
    )
  }
}

export default App
