// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bakery-js": () => import("./../../../src/pages/bakery.js" /* webpackChunkName: "component---src-pages-bakery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mystics-js": () => import("./../../../src/pages/mystics.js" /* webpackChunkName: "component---src-pages-mystics-js" */)
}

