import React from 'react';
import Nav from '@components/MainHeader'
import Footer from '@components/Footer'
import MusicPlayer from '@components/MusicPlayer'
import Head from '@components/Head'

export default function Layout({ children }) {
  return (
    <>
      <div class="dummy"></div>
      <Head />
      <Nav />
      <MusicPlayer />
      {children}
      <Footer />
    </>
  );
}
