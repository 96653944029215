import React from "react";
import "./style.css";

export default function Footer() {
    return (
      <div id="footer">
        <p>&copy; 2022. All Rights Reserved.</p>
      </div>
    );
}
